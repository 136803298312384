
#order-management .filters-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
    margin-top: -60px;
}

#order-management .filters-area form {
    margin-bottom: 5px;
    margin-right: 16px;
}

#order-management .filters-area form input {
    border-radius: 25px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #e1e1e1;
    min-width: 282px;
}

#order-management .filters-area button {
    font-weight: normal;
}

#order-management .filters-area button:nth-of-type(1) {
    margin-right: 32px;
}

#order-management .filters-area button .button-text {
    margin-right: 20px;
}

#order-management .filters-area button.MuiButton-textPrimary {
    background-color: rgba(25, 77, 151, 0.1);
}

#order-management .orders-section .pantalone-table {
    margin-top: 8px;
}

#order-management .orders-table td {
    font-size: 14px;
}

#order-management .orders-table .shipping-address-item b {
    display: block;
}

.shipping-address-item {
    display: flex;
    flex-direction: column;
}

#order-management .orders-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    max-width: 250px;
}

#order-management .active-order-section .top-section .order-section-card {
    border: 1px solid #afafaf;
    padding: 10px 16px;
    margin-top: 24px;
    border-radius: 0;
    overflow-wrap: break-word;
}

#order-management .active-order-section .top-section .order-section-card .order-detail-item,
#order-management .active-order-section .top-section .order-section-card .section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
}

#order-management .active-order-section .top-section .order-section-card .order-detail-item:not(:first-child),
#order-management .active-order-section .top-section .order-section-card .section-item:not(:first-child) {
    margin-top: 18px;
}

#order-management .active-order-section .top-section .order-section-card .order-detail-item strong {
    color: #000;
    font-size: 15px;
}

#order-management .active-order-section .table-title {
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-menu li[role="menuitem"] > label,
.sort-menu li[role="menuitem"] > label {
    pointer-events: none;
    user-select: none;
}

.filter-inner-view-dialog .custom-dates-form {
    width: 400px;
    max-width: 100%;
}
.filter-inner-view-dialog .custom-dates-form .error-message {
  font-size: 14px;
  margin-top: 5px;
  color: #f41216;
}

.filter-inner-view-dialog .custom-dates-form > div:nth-of-type(1) {
    margin-bottom: 20px;
}

.filter-inner-view-dialog .custom-dates-form .actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-inner-view-dialog .custom-dates-form .actions button {
    width: 48%;
    max-width: 48%;
}

.filter-inner-view-dialog .order-statuses {
    width: 400px;
    max-width: 100%;
}

.filter-inner-view-dialog .order-statuses .actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-inner-view-dialog .order-statuses .actions button {
    width: 48%;
    max-width: 48%;
}

.filter-inner-view-dialog .delivery-addresses {
    width: 400px;
    max-width: 100%;
}

.filter-inner-view-dialog .delivery-addresses .actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-inner-view-dialog .delivery-addresses .actions button {
    width: 48%;
    max-width: 48%;
}

#order-management .orders-products-table {
    margin-top: 8px;
}


#order-management .orders-products-table tbody td {
    font-size: 15px;
}

#order-management .orders-products-table tbody td:first-child {
    font-weight: 600;
}


#order-management .orders-products-table tbody .price {
    white-space: nowrap;
    display: flex;
}


#order-management .orders-products-table tbody .price .span{
    margin-right: 5px;
}

.pantalone-table {
    box-shadow: none!important;
}

.pantalone-table table {
    border-collapse: unset;
}

.pantalone-table thead th {
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #d0d0d0;
    border-right-width: 0;
    border-left-width: 0;
}

.pantalone-table thead th:first-child {
    border-left-width: 1px;
}

.pantalone-table thead th:last-child {
    border-right-width: 1px;
}

.pantalone-table tbody {
    background-color: #cbdce9;
}

.pantalone-table tbody td {
    font-size: 14px;
    border-bottom: 1px solid #d0d0d0;
}

@media screen and (max-width: 768px) {
    #order-management .filters-area {
        display: block;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        flex-wrap: nowrap;
        margin-top: -14px;
    }
    #order-management .filters-area form input {
        min-width: 100% !important;
    }
    #order-management .active-order-section .top-section .order-section-card {
        width: 240px;
    }
    #order-management .active-order-section .top-section .order-details .MuiTypography-h1{
        font-size: 20px;
     }
}

@media screen and (max-width: 425px) {
    #order-management .active-order-section .top-section .order-section-card {
        width: 220px;
    }
}
