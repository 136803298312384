
#cart .page-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
}

#cart .page-title small {
    font-weight: 500;
    margin-left: 8px;
}

#cart .pantalone-table {
    max-height: 55vh;
    max-height: calc(var(--vh, 1vh) * 55);
}

#cart .cart-table thead th:last-child,
#cart .cart-table tbody td:last-child {
    padding-right: 30px;
}

#cart .cart-table thead th:first-child,
#cart .cart-table tbody td:first-child {
    padding-left: 30px;
}

#cart .cart-table .product-name .name {
    font-weight: 600;
    font-size: 14px;
}

#cart .cart-table .product-name .description {
    font-weight: 500;
    max-width: 300px;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
}

#cart .cart-table .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#cart .cart-table .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

#cart .cart-table .price {
    white-space: nowrap;
    display: flex;
}
#cart .cart-table .price .span{
    margin-right: 5px;
}

#cart .cart-table .stock-item .last-update {
    display: block;
}

#cart .cart-table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d0d0d0;
    padding: 16px 45px 16px 30px;
}

#cart .cart-table-footer .total-cart-amount {
    font-weight: 700;
    font-size: 19px;
    margin-right: 30px;
    white-space: nowrap;
}

#cart .cart-table-footer .price {
    font-weight: 700;
    font-size: 19px;
    white-space: nowrap;
}

#cart .cart-page-footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 50px;
}

#cart .cart-page-footer > .button-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#cart .cart-page-footer > .button-group > button {
    width: 300px;
}

#cart .cart-page-footer > .button-group > button:not(:last-child) {
    margin-right: 20px;
}

#cart .cart-page-footer > .button-group .stock_check_dailog_btn {
    width: 300px;
    margin-right: 20px;
}

#cart .cart-table.__is-checkout {
    width: 120vw;
    width: calc(100vw + 20%);
}

@supports (position:sticky) { 
    
    #cart .cart-table.__is-checkout thead th:last-child {
        position: sticky;
        right: 0;
    }

    #cart .cart-table.__is-checkout tbody td:last-child {
        position: sticky;
        right: 0;
        background-color: #cbdce9;
    }

    #cart .cart-table-footer.__is-checkout {
        position: sticky;
        left: 0;
        bottom: 0;
        background-color: #fff;
    }

}

/* DELETE CART DIALOG */
#delete-cart-item-dialog .custom-dialog-wrapper {
    border-radius: 25px;
}

#delete-cart-item-dialog .dialog-content {
    text-align: center;
    padding-top: 10px;
}

#delete-cart-item-dialog .dialog-content strong {
    font-weight: 600;
    max-width: 70%;
    display: block;
    margin: 0 auto;
}

#delete-cart-item-dialog .dialog-content .button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 55px;
}

#delete-cart-item-dialog .dialog-content .button-group button {
    width: 46%;
    font-weight: bold;
}

#delete-cart-item-dialog .dialog-content .button-group button:first-child {
    background-color: #d80100;
    color: #fff;
}

#delete-cart-item-dialog .dialog-content .button-group button:last-child {
    border-color: #d80100;
    color: #d80100;
}

/* SUCCESS ORDER DIALOG */
#submit-order-dialog .custom-dialog-wrapper {
    padding: 25px 50px;
    border-radius: 25px;
    width: 530px;
}
.success-view {
    padding:20px
}

#submit-order-dialog .custom-dialog-wrapper .close-button svg {
    font-size: 24px;
}

#submit-order-dialog .custom-dialog-wrapper .MuiDialogContent-root {
    padding: 0;
}

#submit-order-dialog .view-order-button {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    display: block;
}

#submit-order-dialog .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

#submit-order-dialog .action {
    font-size: 16px;
    font-weight: 600;
    display: block;
    text-align: center;
    margin-top: 20px;
}

#submit-order-dialog .order-id {
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    display: block;
    margin-right: 12px;
}

#submit-order-dialog .list {
    margin-top: 30px;
}

#submit-order-dialog .list .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#submit-order-dialog .list .list-item:not(:last-child) {
    margin-bottom: 20px;
}

#submit-order-dialog .list .list-item strong {
    font-weight: 700;
    font-size: 14px;
    margin-right: 12px;
}

#submit-order-dialog .list .list-item span {
    font-size: 14px;
}

#submit-order-dialog .to-be-shipped-view .view-title {
    text-align: center;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
}

#submit-order-dialog .to-be-shipped-view .form-control-label {
    width: 100%;
    margin-top: 18px;
}

#submit-order-dialog .to-be-shipped-view .select-address-label {
    font-weight: 600;
    max-width: 100%;
}

#submit-order-dialog .to-be-shipped-view .form-control-label .MuiFormControlLabel-label {
    width: 100%;
}

#submit-order-dialog .to-be-shipped-view .button-group {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#submit-order-dialog .to-be-shipped-view .button-group button:first-child {
    width: 100px;
    font-weight: 600;
}

#submit-order-dialog .to-be-shipped-view .button-group button:last-child {
    margin-left: 3px;
    font-weight: 600;
    border : 2px solid #194d97;
}

#submit-order-dialog .new-address-success-section {
    text-align: center;
    width: 400px;
    max-width: 100%;
    max-height: 100%;
}

#submit-order-dialog .new-address-success-section .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#submit-order-dialog .new-address-success-section .description {
    margin-bottom: 30px;
    padding: 0 40px;
}

@media screen and (max-width: 767px) {  
    #cart .cart-page-footer > .button-group > button:not(:last-child) {
    margin-right: 10px;
    } 
    #cart .cart-page-footer > .button-group > button {
        width: 100px;
    }
    #cart .cart-page-footer > .button-group .stock_check_dailog_btn {
        width: 100px;
        margin-right: 10px;
    }
    #cart .cart-page-footer > .button-group .stock_check_dailog_btn span {
        font-size: 13px;
        white-space: nowrap;
    }
    #cart .cart-page-footer > .button-group > button span span{
        font-size: 13px;
        white-space: nowrap;
    }
    #cart .cart-page-footer > .button-group > button span {
        font-size: 13px;
        white-space: nowrap;
    }
    #cart .cart-table-footer .total-cart-amount {
        margin-left: -22px;
    }
    #cart .cart-page-footer {
        display: block;
        
    }
    #submit-order-dialog .to-be-shipped-view .button-group button:last-child {
        font-size: 10px;
        line-height: 1.2;
    }
    .text-field .input-wrapper textarea {
        min-width: 160px;       
    }
    #cart .cart-table-footer {
        border-right: none;
    }
}
@media screen and (max-width: 320px) {
    #cart .cart-page-footer > .button-group > button span span{
        font-size: 10px;
    }
    #cart .cart-page-footer > .button-group > button span {
        font-size: 10px;
    }
}

