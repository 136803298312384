#notifications .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 12px;
    margin-left: 23px;
}

#notifications .page-title svg {
    font-size: 32px;
}

#notifications .page-title .title {
    font-weight: 600; 
}

#notifications .notification-list .notification-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 28px;
    background-color: #cbdce9;
    border-bottom: 1px solid #99a3ae;
    position: relative;
    max-width: 100%;
}
#notifications .notification-list .notification-item > button{
    position: absolute;
    right: 24px;
    padding-left: 0;
    height: 30px;
    font-size: 14px !important;
}
#notifications .notification-list .notification-item img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

#notifications .notification-list .notification-item span {
    margin-left: 16px;
}

@media screen and (max-width: 768px) {
    #notifications .notification-list {
        margin-left: -20px;
    }
    #notifications .notification-list .notification-item > button{
        right: 9px;        
    }
}
@media screen and (max-width: 425px) {
    #notifications .notification-list .notification-item > button {
        margin-top: 8px;
    }
    #notifications .notification-list .notification-item {
        padding: 15px 10px;
    }
    #notifications .notification-list .notification-item span {
        font-size: 14px;
    }
    #notifications .page-title {
        margin-left: 0;
    }
}

@media screen and (max-width: 320px) {
    #notifications .notification-list .notification-item > button {
        margin-top: 24px;
    }
}