
#landingpage .category-item:not(.__is-chip) {
    flex: 0 0 200px;
    max-width: 200px;
    width: 200px;
    height: 220px;
}

#landingpage .category-item:not(.__is-chip) .__chip-item {
    height: 0;
}

#landingpage .category-item > .MuiPaper-root{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
}

#landingpage .category-item > .MuiPaper-root > button,
#landingpage .category-item > .MuiPaper-root .MuiCardContent-root {
    height: 100%;
}

#landingpage .category-item > .MuiPaper-root .MuiCardContent-root {
    padding: 12px;
}

#landingpage .category-item .MuiCardMedia-root {
    height: 130px;
}

#landingpage .category-item .category-name {
    font-size: 13px;
    font-weight: bold;
}

#landingpage .sub-category-section .divider {
    margin-top: 40px;
    margin-bottom: 50px;
}
#landingpage .sub-category-section-block .divider {
    /* margin-top: 40px; */
    margin-bottom: 50px;
}
#landingpage .__chip-item:not(.__is-selected) {
    background-color: #cbdbea;
    color: #000;
}

#landingpage .__chip-item {
    font-size: 12px;
}

#landingpage .__chip-item.__is-selected {
    font-weight: 600;
}
@media screen and (max-width: 767px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 160px;
        max-width: 160px;
    }
    #landingpage .cattegory-sub-item {
        height: 95% !important;       
    }
    #landingpage .category-item-sub:not(.__is-chip) {
       margin: 12px 0;
    }
}
@media screen and (max-width: 425px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 154px;
        max-width: 154px;
        width: 221px;
        height: 240px;
    }
    #landingpage .category-item .MuiCardMedia-root {
        height: 110px;
    }
    #landingpage .cattegory-sub-item {
        height: 85% !important;       
    }
    #landingpage .category-item-sub:not(.__is-chip) {
        margin: -15px 0;
     }
}
@media screen and (max-width: 375px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 135px;
        max-width: 135px;
        width: 184px;
        height: 240px;
    }
}
@media screen and (max-width: 390px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 135px;
        max-width: 135px;
        width: 184px;
        height: 240px;
    }
    #landingpage .category-item .MuiCardMedia-root {
        height: 100px;
    }
}
@media screen and (max-width: 360px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 128px;
        max-width: 128px;
        width: 184px;
        height: 240px;
    }
}
@media screen and (max-width: 320px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 110px;
        max-width: 110px;
        width: 184px;
        height: 215px;
    }
    #landingpage .category-item .category-name {
        font-size: 10px;
    }
}