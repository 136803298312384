
#catalogue .catalogue-no-products-found {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35vh;
    font-weight: 600;
}

#catalogue .products-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#catalogue .products-header.__has-search-value {
    justify-content: center;
}

#catalogue .products-header.__has-search-value .breadcrumb {
    display: none!important;
}


#catalogue .products-header .cart-count {
    padding: 8px 0;
    width: 280px;
    text-align: center;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    margin-top: 5px;
    margin-left: -140px;
}

#catalogue .products-header .breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
}

#catalogue .products-header .breadcrumb button,
#catalogue .products-header .breadcrumb strong {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    min-width: 0;
    background-color: unset;
}

#catalogue .products-header .breadcrumb > svg {
    margin: 0 5px;
}

#catalogue .filter-items {
    margin-top: 20px;
}

#catalogue .filter-items .filter-title {
    font-size: 14px;
    margin-bottom: 8px;
}

#catalogue .filter-items .__chip-item {
    height: 26px;
    padding-left: 20px;
    padding-right: 20px;
}

#catalogue .filter-items .__chip-item:not(.__is-selected) {
    background-color: #cbdbea!important;
    color: #000!important;
}

#catalogue .filter-items .__chip-item.__is-selected {
    font-weight: 600;
}

#catalogue .products-table-loading {
    margin-top: 30px;
}

#catalogue .products-table {
    margin-top: 50px;
}

#catalogue .products-table .pantalone-table {
    height: 60vh;
    min-height: 400px;
}

#catalogue .products-table .table-title-section {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}

#catalogue .products-table .id-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#catalogue .products-table .id-item svg {
    color: #000;
}

#catalogue .products-table .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#catalogue .products-table .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

#catalogue .products-table .add-to-cart {
    width: 160px;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
}

@keyframes iconAreaAni {
    0% { opacity: 0;}
    100% { opacity: 1; }
}

#catalogue .products-table .add-to-cart .icon-area {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #16a085;
    color: #fff;
    user-select: none;
    pointer-events: none;
    animation: iconAreaAni 0.5s alternate ease-in-out;
}

#catalogue .products-table .add-to-cart .icon-area .check-circle-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: iconAreaAni 1s alternate ease-in-out;
}

#catalogue .products-table .loading-section {
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
    width: 100%;
}

#catalogue .products-table .price {
    white-space: nowrap;
    display: flex;
}
#catalogue .products-table .price .span {
    margin-right: 5px;
}

/*
@supports (position:sticky) { 
    #catalogue .products-table .loading-section {
        position: sticky;
        left:0;
        bottom:0;
    }   
}
*/

#product-quick-view-dialog .MuiDialog-container {
    align-items: flex-end;
}

#product-quick-view-dialog .custom-dialog-wrapper {
    width: 600px;
    margin-bottom: 120px;
}

#product-quick-view-dialog strong {
    font-weight: 600;
    margin-right: 20px;
}

#product-quick-view-dialog b {
    font-weight: 600;
}

#product-quick-view-dialog .product-id-section {
    font-size: 14px;
    margin-bottom: 10px;
}

#product-quick-view-dialog .product-id-section * {
    font-size: 13px;
}

#product-quick-view-dialog .product-details-section .section-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#product-quick-view-dialog .product-details-section .section-item:first-child {
    margin-bottom: 15px;
}

#product-quick-view-dialog .product-details-section .section-item:last-child {
    margin-bottom: 60px
}

/* Request Product */

#catalogue .products-table .no-products-found {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 40px;
}

#catalogue .products-table .no-products-found > span:nth-of-type(2) {
    font-weight: 600;
    font-size: 14px;
    margin-right: 35px;
}

#catalogue .products-table .no-products-found button {
    position: relative;
    right: 30px;
    font-weight: 600;
    font-size: 14px;
}

#request-new-product .custom-dialog-wrapper {
    width: 450px;
}

#request-new-product .form-view .forgot-password-description {
    margin-top: 30px;
    text-align: center;
}

#request-new-product .form-view .text-field {
    margin-top: 20px;
    margin-bottom: 50px;
}

#request-new-product .form-view .loading-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    min-height: 150px;
}

#request-new-product .submitted-view {
    text-align: center;
}

#request-new-product .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#inputContainerMain{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}
#inputContainerError{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    }
    #inputContainerErrorMsg{
        color: red;
        display: inline;
        margin-bottom: 8px;
}
#request-new-product .description {
    margin-bottom: 30px;
    padding: 0 40px;
    text-align: center;
}

#request-new-product button {
    font-weight: bold;
}
@media screen and (max-width: 767px) { 
    #catalogue .table-title-section span {
        white-space: nowrap;
    }
    #catalogue .table-title-section h3{
       font-size: 18px;
    }
}
@media screen and (max-width: 400px) { 
    #catalogue .products-table .no-products-found button {
        white-space: nowrap;
        font-size: 12px;
    }
}
.titleFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}