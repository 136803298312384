#shipment-document .shipment-document-table .shipping-address-item b {
    display: block;
}

#shipment-document .shipment-document-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    max-width: 250px;
}
#shipment-document .shipment-document-table  thead tr th:nth-child(4) {
    width: 26%;
 }

#shipment-document .shipment-document-second-table .shipping-address-item b {
    display: block;
}

#shipment-document .shipment-document-second-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    max-width: 250px;
}
#shipment-document .shipment-document-second-table  thead tr th:nth-child(2) {
    width: 28%;
 }
 #shipment-document .shipment-document-second-table  thead tr th:nth-child(1) {
    width: 12%;
 }
 #shipment-document .shipment-document-second-table  thead tr th:nth-child(3),
 #shipment-document .shipment-document-second-table  thead tr th:nth-child(4)  {
    width: 15%;
 }

 #shipment-document .shipment-document-second-table  thead tr th:nth-child(5) {
    width: 12%;
 }
