@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap);
html, body, #root {
    height: 100%;
}

body {
    overflow: hidden;
}

#root > div {
    width: 100%!important;
    height: auto!important;
}

#root > div > div:nth-of-type(1) {
    display: none!important;
}

/* HEADER */
header {
    height: 120px;
    background-color: #e6e6e6;
    width: 100%;
}

header .header-wrapper {
    padding: 10px 50px 0 15px;
    height: 100%;
}

header .header-wrapper-left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

header .header-wrapper .header-wrapper-left-side .logo-button {
    background-color: unset!important;
    padding: 0;
    flex: 0 0 225px;
    max-width: 225px;
}

header .header-wrapper .header-wrapper-left-side .logo-button img{
    width: 235px;
}

header .header-wrapper .header-wrapper-left-side form {
    width: 100%;
}

header .header-wrapper .header-wrapper-left-side .text-field {
    margin-left: 46px;
}

header .header-wrapper .header-wrapper-left-side .text-field input {
    border-radius: 25px;
    border: 0;
    max-width: 100%;
    font-weight: 500;
    padding-left: 25px;
    padding-right: 25px;
    height: 45px;
    font-size: 16px;
    font-weight: normal;
}

header .header-wrapper .header-wrapper-right-side {
    text-align: right;
    margin-top: -20px;
}

header .header-wrapper .header-wrapper-right-side .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header .header-wrapper .header-wrapper-right-side .top .language-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header .header-wrapper .header-wrapper-right-side .top .language-selector .divider {
    height: 15px;
    width: 2px;
    background-color: #000;
    min-height: 0;
}

header .header-wrapper .header-wrapper-right-side .top .language-selector button {
    min-width: 0;
    font-size: 12px;
}

header .header-wrapper .header-wrapper-right-side .top .logout-button {
    margin-left: 25px;
    font-size: 12px;
}
header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
    margin-left: 24px;
    margin-right: 14px;
    font-size: 12px;
}

header .header-wrapper .header-wrapper-right-side .bottom button small {
    font-size: 13px;
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
}
header .header-wrapper .header-wrapper-right-side .bottom .badge-none .MuiBadge-badge{
   display: none;
}
header .header-wrapper .header-wrapper-right-side .bottom .badge-block .MuiBadge-badge {
    display: block;
    padding: 4px 6px;
 }
header .header-wrapper .header-wrapper-right-side .bottom button > span {
    flex-wrap: wrap;
}

header .header-wrapper .header-wrapper-right-side .bottom button svg {
    flex: 1 1 100%;
    max-width: 100%;
    font-size: 30px;
}

/* CONTENT */
.main-layout-wrapper {
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    height: calc(100% - 120px);
    flex-wrap: nowrap!important;
}

.main-layout-without-sidebar {
    padding: 15px 30px;
    height: 100%;
    height: calc(100vh - 120px);
    overflow: auto;
}

.main-layout-wrapper.__use-header,
.main-layout-without-sidebar.__use-header {
    height: calc(100vh - 120px);
}

.main-layout-wrapper:not(.__use-header),
.main-layout-without-sidebar:not(.__use-header) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.main-layout-wrapper aside {
    border: 1px solid #afafaf;
    border-left:0;
    border-bottom: 0;
    height: auto;
    flex: 0 0 260px;
    max-width: 260px;
    width: 260px;
    padding-top: 30px;
    position: relative;
}

.main-layout-wrapper .open{
    border: 1px solid #afafaf;
    border-left:0;
    border-bottom: 0;
    height: auto;
    flex: 0 0 260px;
    max-width: 260px;
    width: 260px;
    padding-top: 30px;
    position: relative;
}

.main-layout-wrapper aside .menu-items button {
    font-size: 18px;
    border-radius: 0;
    font-weight: 600;
}
.main-layout-wrapper aside .menu-items button span {
    padding-right: 13.5px;
}
.main-layout-wrapper aside .menu-items button:not(.__is-active) {
    color: #969696;
}

.main-layout-wrapper aside .menu-items button:not(:last-child) {
    margin-bottom: 20px;
}

.main-layout-wrapper aside .menu-items button > span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
}

.main-layout-wrapper aside .menu-items button .shipments-item {
    text-align: left;
}

.main-layout-wrapper aside .menu-items button .shipments-item > span {
    display: inline-block;
    text-align: left;
}

.main-layout-wrapper aside .menu-items button svg {
    font-size: 26px;
    margin-right: 20px;
}

.main-layout-wrapper .content {
    flex: 1 1 auto;
    max-width: 100%;
    padding: 45px 30px 45px 30px;
    height: 100%;
    height: calc(100vh - 120px);
    overflow: auto;
}

.main-layout-wrapper .content .page-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 700;
}

.main-layout-wrapper .content .padding-box {
    padding-bottom: 45px;
}
.main-layout-wrapper .menu-items .toggle-button {       
   display: none;
}

@media screen and (max-width: 767px) {
    .main-layout-wrapper aside .menu-items {
        margin-top: 25px;
    } 
    header .header-wrapper {
        padding: 0px 25px 0 15px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button {
        background-color: unset!important;
        padding: 0;
        flex: 0 0 180px;
        max-width: 180px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button img{
        width: 200px;
        margin-bottom: 40px;
    }
    .main-layout-wrapper aside {
       overflow: auto;
       overflow-x: hidden;
    }
    header {
        height: 150px;
    }
    header .header-wrapper {
        position: relative;
    }
    header .header-wrapper .header-wrapper-left-side form {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 15px;
        margin-bottom: 14px;
        margin-left: -19px;
    }
    header .header-wrapper .header-wrapper-left-side .text-field {
        margin-left: 20px;
    }
    header .header-wrapper .header-wrapper-right-side .top {
        margin-top: -45px;
    }
    .main-layout-wrapper .closed {       
        flex: 0 0 60px;
        max-width: 260px;
    }
    .main-layout-wrapper .menu-items .toggle-button {  
        display: block;     
        position: absolute;
        top: 0;
        margin-left: -4px;
        margin-top: 9px;
    }
    .main-layout-wrapper .menu-items .toggle-button-cross-icon {  
        position: absolute;
        top: 1px;
        right: -21px;
    }
    .main-layout-wrapper aside .menu-items button > span {
        padding-left: 12px;
    }
    .main-layout-wrapper aside .menu-items button svg {
        margin-right: -8px;
    }
    .main-layout-wrapper aside .menu-items button span {
        padding-right: 32.5px;
    }
     header .header-wrapper .header-wrapper-right-side .bottom button small {
        display: none;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        margin-left: -3px;
        margin-right: 0;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-left: -2px;
    }
    .main-layout-wrapper.__use-header,
    .main-layout-without-sidebar.__use-header {
        height: calc(100vh - 170px);
    }
    #landingpage .category-item .category-name {
        font-size: 10px;
    }
    .main-layout-wrapper .closed .menu-items button .MuiButton-label span:last-child{
        display: none;
     } 
     .text-field.__full-width {
        max-width: 100% !important;
    }
    .main-layout-wrapper .content .page-title {
        font-size: 16px;
    }
}

@media screen and (max-width: 540px) {   
    header .header-wrapper .header-wrapper-right-side .bottom {
        display: flex;
     }
}
@media screen and (max-width: 412px) {
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-right: 0px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        margin-right: -10px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-right: -10px;
    }
}

@media screen and (max-width: 375px) {
    header .header-wrapper .header-wrapper-right-side .bottom button small {
        display: none;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        margin-right: -15px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-right: -15px;
    }
}

@media screen and (max-width: 320px) {
    header .header-wrapper {
        padding: 0px 40px 0 15px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button {
        background-color: unset!important;
        padding: 0;
        flex: 0 0 140px;
        max-width: 140px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button img{
        width: 150px;
        margin-bottom: 40px;
    }
    header .header-wrapper .header-wrapper-right-side .top .language-selector button {
        font-size: 10px;
    }
    
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        font-size: 10px;
        margin-right: -40px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        font-size: 10px;
        margin-right: -40px;
    }
    header .header-wrapper .header-wrapper-right-side .bottom button svg {
        font-size: 25px;
    }
    .main-layout-wrapper .closed {
        flex: 0 0 50px;
        max-width: 260px;
    }
    .main-layout-wrapper aside .menu-items button > span{
        padding-left: 7px;
    } 
    .main-layout-wrapper .closed .menu-items button .MuiButton-label span:last-child{
       display: none;
    }       
    .main-layout-wrapper aside .menu-items button svg {
        font-size: 22px;
    }
    header .header-wrapper .header-wrapper-left-side .text-field input {
        height: 40px;
    }
    header .header-wrapper .header-wrapper-right-side .top {
        margin-top: -28px;
    }
    .main-layout-wrapper aside .menu-items button span {
        padding-right: 35.5px;
    }
    #landingpage .category-item .category-name {
        font-size: 8px;
    }
}

/* DIALOG */
.custom-dialog {
    border-radius: 8px;
    position: relative;
}

.custom-dialog .custom-dialog-wrapper {
    padding: 20px;
    min-width: 300px;
    max-width: 500px;
    /* max-width: 100%; */
}

.custom-dialog .custom-dialog-wrapper .dialog-title {
    text-align: center;
    margin-bottom: 16px;
}

.custom-dialog .custom-dialog-wrapper .close-button {
    position: absolute;
    right: 0;
    top:0;
    color: #000;
}

.custom-dialog .custom-dialog-wrapper .close-button svg {
    font-size: 15px;
}

.react-router-modal__container {
    z-index: 1500;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .custom-dialog .MuiBackdrop-root {
        -webkit-backdrop-filter: -webkit-blur(2px);
                backdrop-filter: -webkit-blur(2px);
        backdrop-filter: blur(2px);
    }
}


/* INPUT */
.text-field {}

.text-field.__full-width {
    width: 100%;
}

.text-field.__full-width input,
.text-field.__full-width textarea {
    width: 100%;
}

.text-field.__start-adornment .input-wrapper input {
    padding-left: 50px;
}

.text-field.__end-adornment .input-wrapper input {
    padding-right: 50px;
}

.text-field label {
    display: inline-block;
    margin-bottom: 4px;
}

.text-field .input-wrapper {
    position: relative;
}

.text-field .input-wrapper input {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    height: 40px;
    min-width: 220px;
    padding: 0 8px;
    outline: none;
    background-color: #fff;
    font-weight: 500;
    outline: unset;
}

.text-field .input-wrapper textarea {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    min-width: 220px;
    min-height: 60px;
    padding: 10px;
    outline: none;
    background-color: #fff;
    font-weight: 500;
    outline: unset;
}

.text-field .input-wrapper input:not(:focus):not(:hover),
.text-field .input-wrapper textarea:not(:focus):not(:hover) {
    border-color: #cac9c9!important;
}

.text-field .input-wrapper .start-adornment {
    position: absolute;
    left: 0;
    top:0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 4px;
    padding-right: 4px;
}

.text-field .input-wrapper .end-adornment {
    position: absolute;
    right: 0;
    top:0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 4px;
    padding-right: 4px;
}

.text-field .input-wrapper .start-adornment button,
.text-field .input-wrapper .end-adornment button {
    height: 100%;
    width: 40px;
}

.text-field .error-message {
    font-size: 12px;
    font-weight: 600;
    color: #f41216;
}

.text-field .extra-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

/* BUTTONS */
.rounded-button {
    border-radius: 25px;
}

/* Contact Us Dialog */
#contact-us-dialog .custom-dialog-wrapper  {
    width: 400px;
    padding-bottom: 50px;
}

#contact-us-dialog .emails {
    margin-top: 10px;
}

#contact-us-dialog .emails a {
    display: block;
    text-align: center;
    font-size: 17px;
}

#contact-us-dialog .emails a:not(:last-child) {
    margin-bottom: 15px;
}

.contact-us-wrapper .text-section {
    display: flex;
    align-items: center;
}

.contact-us-wrapper .text-section .contact-us-text {
    font-weight: bold;
    margin-right: 4px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: pointer;
}
.react-router-modal__modal {
    padding: 20px;
    border-radius: 8px;
    min-width: 300px;
    text-align: center;
}

.react-router-modal__modal * {
    justify-content: center;
    margin-left: 0;
}

.react-router-modal__modal div[role="button"] {
    width: 150px;
}

.react-router-modal__modal > div > div:nth-of-type(1) {
    font-size: 24px;
}

.react-router-modal__modal > div > div:nth-of-type(2) {
    font-size: 16px;
}

/* Pantalone Table */
.pantalone-table {
    box-shadow: none!important;
}

.pantalone-table table {
    border-collapse: unset;
}

.pantalone-table thead th {
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #d0d0d0;
    border-right-width: 0;
    border-left-width: 0;
}

.pantalone-table thead th:first-child {
    border-left-width: 1px;
}

.pantalone-table thead th:last-child {
    border-right-width: 1px;
}

.pantalone-table tbody {
    background-color: #cbdce9;
}

.pantalone-table tbody td {
    font-size: 14px;
    border-bottom: 1px solid #d0d0d0;
    max-width: 300px;
}

#profile-page .center-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#profile-page .grid-container .item:not(:last-child) {
    margin-bottom: 25px;
}

#profile-page .grid-container .item > label {
    font-weight: 600;
    display: block;
    margin-bottom: 18px;
    font-size: 15px;
}

#profile-page .grid-container .item > .value {
    margin-left: 15px;
    font-weight: 600;
    font-size: 15px;
}

#mobile-phone-info .MuiPaper-root {
    padding: 15px 25px;
    max-width: 100%;
    width: 280px;
    position: relative;
    left: 50px;
    top: 50px;
    font-size: 14px;
}

#mobile-phone-info .MuiPaper-root b {
    font-weight: 600;
}

.mobile-phone-info-contact-us .text-section span {
    font-size: 14px;
}


#profile-page .grid-container .left-side .phone-number-item .value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#profile-page .grid-container .left-side .phone-number-item .value > span {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}


#profile-page .grid-container .left-side .phone-number-item .value svg {
    position: relative;
    top: 2px;
    font-size: 28px;
    left: -3px;
}

#profile-page .grid-container .right-side .password-item > button,
#profile-page .grid-container .right-side .shipping-address-item > button {
    margin-top: 20px;
    margin-left: auto;
    display: block;
}

#profile-page .grid-container .right-side .shipping-address-item textarea {
    background-color: #cbdbea;
    resize: none;
    height: 150px;
    padding-top: 0;
    line-height: 250%;
    font-size: 16px;
    font-weight: 700;
}

#profile-page .grid-container .right-side .shipping-address-item strong {
    font-weight: 600;
}

#profile-page .grid-container .right-side .shipping-address-item .text-section {
    justify-content: flex-start;
}

/* Request New Address */

#request-new-address-dialog .new-address-section .description {
    mask-type: 40px;
    max-width: 70%;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}

#request-new-address-dialog .new-address-section .text-field textarea {
    height: 200px;
}

#request-new-address-dialog .new-address-section > button {
    margin-top: 20px;
}

#request-new-address-dialog .new-address-section .loading-section {
    text-align: center;
    width: 100%;
    height: 200px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

#request-new-address-dialog .new-address-success-section {
    text-align: center;
    width: 400px;
    max-width: 100%;
    max-height: 100%;
}

#request-new-address-dialog .new-address-success-section .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#request-new-address-dialog .new-address-success-section .description {
    margin-bottom: 30px;
    padding: 0 40px;
}

#request-new-address-dialog .error-message {
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
}

@media screen and (max-width: 767px) { 
    #profile-page .grid-container .right-side .password-item > button,
    #profile-page .grid-container .right-side .shipping-address-item > button {
        font-size: 13px;
    }
    #profile-page .grid-container .right-side .shipping-address-item .text-section span{
        font-size: 14px;
    }
    #profile-page .grid-container .right-side .shipping-address-item .text-section > button span{
        font-size: 14px;
        white-space: nowrap;
    }
}

#order-management .filters-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
    margin-top: -60px;
}

#order-management .filters-area form {
    margin-bottom: 5px;
    margin-right: 16px;
}

#order-management .filters-area form input {
    border-radius: 25px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #e1e1e1;
    min-width: 282px;
}

#order-management .filters-area button {
    font-weight: normal;
}

#order-management .filters-area button:nth-of-type(1) {
    margin-right: 32px;
}

#order-management .filters-area button .button-text {
    margin-right: 20px;
}

#order-management .filters-area button.MuiButton-textPrimary {
    background-color: rgba(25, 77, 151, 0.1);
}

#order-management .orders-section .pantalone-table {
    margin-top: 8px;
}

#order-management .orders-table td {
    font-size: 14px;
}

#order-management .orders-table .shipping-address-item b {
    display: block;
}

.shipping-address-item {
    display: flex;
    flex-direction: column;
}

#order-management .orders-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    max-width: 250px;
}

#order-management .active-order-section .top-section .order-section-card {
    border: 1px solid #afafaf;
    padding: 10px 16px;
    margin-top: 24px;
    border-radius: 0;
    overflow-wrap: break-word;
}

#order-management .active-order-section .top-section .order-section-card .order-detail-item,
#order-management .active-order-section .top-section .order-section-card .section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
}

#order-management .active-order-section .top-section .order-section-card .order-detail-item:not(:first-child),
#order-management .active-order-section .top-section .order-section-card .section-item:not(:first-child) {
    margin-top: 18px;
}

#order-management .active-order-section .top-section .order-section-card .order-detail-item strong {
    color: #000;
    font-size: 15px;
}

#order-management .active-order-section .table-title {
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-menu li[role="menuitem"] > label,
.sort-menu li[role="menuitem"] > label {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.filter-inner-view-dialog .custom-dates-form {
    width: 400px;
    max-width: 100%;
}
.filter-inner-view-dialog .custom-dates-form .error-message {
  font-size: 14px;
  margin-top: 5px;
  color: #f41216;
}

.filter-inner-view-dialog .custom-dates-form > div:nth-of-type(1) {
    margin-bottom: 20px;
}

.filter-inner-view-dialog .custom-dates-form .actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-inner-view-dialog .custom-dates-form .actions button {
    width: 48%;
    max-width: 48%;
}

.filter-inner-view-dialog .order-statuses {
    width: 400px;
    max-width: 100%;
}

.filter-inner-view-dialog .order-statuses .actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-inner-view-dialog .order-statuses .actions button {
    width: 48%;
    max-width: 48%;
}

.filter-inner-view-dialog .delivery-addresses {
    width: 400px;
    max-width: 100%;
}

.filter-inner-view-dialog .delivery-addresses .actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-inner-view-dialog .delivery-addresses .actions button {
    width: 48%;
    max-width: 48%;
}

#order-management .orders-products-table {
    margin-top: 8px;
}


#order-management .orders-products-table tbody td {
    font-size: 15px;
}

#order-management .orders-products-table tbody td:first-child {
    font-weight: 600;
}


#order-management .orders-products-table tbody .price {
    white-space: nowrap;
    display: flex;
}


#order-management .orders-products-table tbody .price .span{
    margin-right: 5px;
}

.pantalone-table {
    box-shadow: none!important;
}

.pantalone-table table {
    border-collapse: unset;
}

.pantalone-table thead th {
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #d0d0d0;
    border-right-width: 0;
    border-left-width: 0;
}

.pantalone-table thead th:first-child {
    border-left-width: 1px;
}

.pantalone-table thead th:last-child {
    border-right-width: 1px;
}

.pantalone-table tbody {
    background-color: #cbdce9;
}

.pantalone-table tbody td {
    font-size: 14px;
    border-bottom: 1px solid #d0d0d0;
}

@media screen and (max-width: 768px) {
    #order-management .filters-area {
        display: block;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        flex-wrap: nowrap;
        margin-top: -14px;
    }
    #order-management .filters-area form input {
        min-width: 100% !important;
    }
    #order-management .active-order-section .top-section .order-section-card {
        width: 240px;
    }
    #order-management .active-order-section .top-section .order-details .MuiTypography-h1{
        font-size: 20px;
     }
}

@media screen and (max-width: 425px) {
    #order-management .active-order-section .top-section .order-section-card {
        width: 220px;
    }
}


#cart .page-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
}

#cart .page-title small {
    font-weight: 500;
    margin-left: 8px;
}

#cart .pantalone-table {
    max-height: 55vh;
    max-height: calc(var(--vh, 1vh) * 55);
}

#cart .cart-table thead th:last-child,
#cart .cart-table tbody td:last-child {
    padding-right: 30px;
}

#cart .cart-table thead th:first-child,
#cart .cart-table tbody td:first-child {
    padding-left: 30px;
}

#cart .cart-table .product-name .name {
    font-weight: 600;
    font-size: 14px;
}

#cart .cart-table .product-name .description {
    font-weight: 500;
    max-width: 300px;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
}

#cart .cart-table .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#cart .cart-table .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

#cart .cart-table .price {
    white-space: nowrap;
    display: flex;
}
#cart .cart-table .price .span{
    margin-right: 5px;
}

#cart .cart-table .stock-item .last-update {
    display: block;
}

#cart .cart-table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d0d0d0;
    padding: 16px 45px 16px 30px;
}

#cart .cart-table-footer .total-cart-amount {
    font-weight: 700;
    font-size: 19px;
    margin-right: 30px;
    white-space: nowrap;
}

#cart .cart-table-footer .price {
    font-weight: 700;
    font-size: 19px;
    white-space: nowrap;
}

#cart .cart-page-footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 50px;
}

#cart .cart-page-footer > .button-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#cart .cart-page-footer > .button-group > button {
    width: 300px;
}

#cart .cart-page-footer > .button-group > button:not(:last-child) {
    margin-right: 20px;
}

#cart .cart-page-footer > .button-group .stock_check_dailog_btn {
    width: 300px;
    margin-right: 20px;
}

#cart .cart-table.__is-checkout {
    width: 120vw;
    width: calc(100vw + 20%);
}

@supports (position:sticky) { 
    
    #cart .cart-table.__is-checkout thead th:last-child {
        position: sticky;
        right: 0;
    }

    #cart .cart-table.__is-checkout tbody td:last-child {
        position: sticky;
        right: 0;
        background-color: #cbdce9;
    }

    #cart .cart-table-footer.__is-checkout {
        position: sticky;
        left: 0;
        bottom: 0;
        background-color: #fff;
    }

}

/* DELETE CART DIALOG */
#delete-cart-item-dialog .custom-dialog-wrapper {
    border-radius: 25px;
}

#delete-cart-item-dialog .dialog-content {
    text-align: center;
    padding-top: 10px;
}

#delete-cart-item-dialog .dialog-content strong {
    font-weight: 600;
    max-width: 70%;
    display: block;
    margin: 0 auto;
}

#delete-cart-item-dialog .dialog-content .button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 55px;
}

#delete-cart-item-dialog .dialog-content .button-group button {
    width: 46%;
    font-weight: bold;
}

#delete-cart-item-dialog .dialog-content .button-group button:first-child {
    background-color: #d80100;
    color: #fff;
}

#delete-cart-item-dialog .dialog-content .button-group button:last-child {
    border-color: #d80100;
    color: #d80100;
}

/* SUCCESS ORDER DIALOG */
#submit-order-dialog .custom-dialog-wrapper {
    padding: 25px 50px;
    border-radius: 25px;
    width: 530px;
}
.success-view {
    padding:20px
}

#submit-order-dialog .custom-dialog-wrapper .close-button svg {
    font-size: 24px;
}

#submit-order-dialog .custom-dialog-wrapper .MuiDialogContent-root {
    padding: 0;
}

#submit-order-dialog .view-order-button {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    display: block;
}

#submit-order-dialog .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

#submit-order-dialog .action {
    font-size: 16px;
    font-weight: 600;
    display: block;
    text-align: center;
    margin-top: 20px;
}

#submit-order-dialog .order-id {
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    display: block;
    margin-right: 12px;
}

#submit-order-dialog .list {
    margin-top: 30px;
}

#submit-order-dialog .list .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#submit-order-dialog .list .list-item:not(:last-child) {
    margin-bottom: 20px;
}

#submit-order-dialog .list .list-item strong {
    font-weight: 700;
    font-size: 14px;
    margin-right: 12px;
}

#submit-order-dialog .list .list-item span {
    font-size: 14px;
}

#submit-order-dialog .to-be-shipped-view .view-title {
    text-align: center;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
}

#submit-order-dialog .to-be-shipped-view .form-control-label {
    width: 100%;
    margin-top: 18px;
}

#submit-order-dialog .to-be-shipped-view .select-address-label {
    font-weight: 600;
    max-width: 100%;
}

#submit-order-dialog .to-be-shipped-view .form-control-label .MuiFormControlLabel-label {
    width: 100%;
}

#submit-order-dialog .to-be-shipped-view .button-group {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#submit-order-dialog .to-be-shipped-view .button-group button:first-child {
    width: 100px;
    font-weight: 600;
}

#submit-order-dialog .to-be-shipped-view .button-group button:last-child {
    margin-left: 3px;
    font-weight: 600;
    border : 2px solid #194d97;
}

#submit-order-dialog .new-address-success-section {
    text-align: center;
    width: 400px;
    max-width: 100%;
    max-height: 100%;
}

#submit-order-dialog .new-address-success-section .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#submit-order-dialog .new-address-success-section .description {
    margin-bottom: 30px;
    padding: 0 40px;
}

@media screen and (max-width: 767px) {  
    #cart .cart-page-footer > .button-group > button:not(:last-child) {
    margin-right: 10px;
    } 
    #cart .cart-page-footer > .button-group > button {
        width: 100px;
    }
    #cart .cart-page-footer > .button-group .stock_check_dailog_btn {
        width: 100px;
        margin-right: 10px;
    }
    #cart .cart-page-footer > .button-group .stock_check_dailog_btn span {
        font-size: 13px;
        white-space: nowrap;
    }
    #cart .cart-page-footer > .button-group > button span span{
        font-size: 13px;
        white-space: nowrap;
    }
    #cart .cart-page-footer > .button-group > button span {
        font-size: 13px;
        white-space: nowrap;
    }
    #cart .cart-table-footer .total-cart-amount {
        margin-left: -22px;
    }
    #cart .cart-page-footer {
        display: block;
        
    }
    #submit-order-dialog .to-be-shipped-view .button-group button:last-child {
        font-size: 10px;
        line-height: 1.2;
    }
    .text-field .input-wrapper textarea {
        min-width: 160px;       
    }
    #cart .cart-table-footer {
        border-right: none;
    }
}
@media screen and (max-width: 320px) {
    #cart .cart-page-footer > .button-group > button span span{
        font-size: 10px;
    }
    #cart .cart-page-footer > .button-group > button span {
        font-size: 10px;
    }
}


#notifications .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 12px;
    margin-left: 23px;
}

#notifications .page-title svg {
    font-size: 32px;
}

#notifications .page-title .title {
    font-weight: 600; 
}

#notifications .notification-list .notification-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 28px;
    background-color: #cbdce9;
    border-bottom: 1px solid #99a3ae;
    position: relative;
    max-width: 100%;
}
#notifications .notification-list .notification-item > button{
    position: absolute;
    right: 24px;
    padding-left: 0;
    height: 30px;
    font-size: 14px !important;
}
#notifications .notification-list .notification-item img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

#notifications .notification-list .notification-item span {
    margin-left: 16px;
}

@media screen and (max-width: 768px) {
    #notifications .notification-list {
        margin-left: -20px;
    }
    #notifications .notification-list .notification-item > button{
        right: 9px;        
    }
}
@media screen and (max-width: 425px) {
    #notifications .notification-list .notification-item > button {
        margin-top: 8px;
    }
    #notifications .notification-list .notification-item {
        padding: 15px 10px;
    }
    #notifications .notification-list .notification-item span {
        font-size: 14px;
    }
    #notifications .page-title {
        margin-left: 0;
    }
}

@media screen and (max-width: 320px) {
    #notifications .notification-list .notification-item > button {
        margin-top: 24px;
    }
}

#catalogue .catalogue-no-products-found {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35vh;
    font-weight: 600;
}

#catalogue .products-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#catalogue .products-header.__has-search-value {
    justify-content: center;
}

#catalogue .products-header.__has-search-value .breadcrumb {
    display: none!important;
}


#catalogue .products-header .cart-count {
    padding: 8px 0;
    width: 280px;
    text-align: center;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    margin-top: 5px;
    margin-left: -140px;
}

#catalogue .products-header .breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
}

#catalogue .products-header .breadcrumb button,
#catalogue .products-header .breadcrumb strong {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    min-width: 0;
    background-color: unset;
}

#catalogue .products-header .breadcrumb > svg {
    margin: 0 5px;
}

#catalogue .filter-items {
    margin-top: 20px;
}

#catalogue .filter-items .filter-title {
    font-size: 14px;
    margin-bottom: 8px;
}

#catalogue .filter-items .__chip-item {
    height: 26px;
    padding-left: 20px;
    padding-right: 20px;
}

#catalogue .filter-items .__chip-item:not(.__is-selected) {
    background-color: #cbdbea!important;
    color: #000!important;
}

#catalogue .filter-items .__chip-item.__is-selected {
    font-weight: 600;
}

#catalogue .products-table-loading {
    margin-top: 30px;
}

#catalogue .products-table {
    margin-top: 50px;
}

#catalogue .products-table .pantalone-table {
    height: 60vh;
    min-height: 400px;
}

#catalogue .products-table .table-title-section {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}

#catalogue .products-table .id-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#catalogue .products-table .id-item svg {
    color: #000;
}

#catalogue .products-table .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#catalogue .products-table .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

#catalogue .products-table .add-to-cart {
    width: 160px;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
}

@keyframes iconAreaAni {
    0% { opacity: 0;}
    100% { opacity: 1; }
}

#catalogue .products-table .add-to-cart .icon-area {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #16a085;
    color: #fff;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    pointer-events: none;
    animation: iconAreaAni 0.5s alternate ease-in-out;
}

#catalogue .products-table .add-to-cart .icon-area .check-circle-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: iconAreaAni 1s alternate ease-in-out;
}

#catalogue .products-table .loading-section {
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
    width: 100%;
}

#catalogue .products-table .price {
    white-space: nowrap;
    display: flex;
}
#catalogue .products-table .price .span {
    margin-right: 5px;
}

/*
@supports (position:sticky) { 
    #catalogue .products-table .loading-section {
        position: sticky;
        left:0;
        bottom:0;
    }   
}
*/

#product-quick-view-dialog .MuiDialog-container {
    align-items: flex-end;
}

#product-quick-view-dialog .custom-dialog-wrapper {
    width: 600px;
    margin-bottom: 120px;
}

#product-quick-view-dialog strong {
    font-weight: 600;
    margin-right: 20px;
}

#product-quick-view-dialog b {
    font-weight: 600;
}

#product-quick-view-dialog .product-id-section {
    font-size: 14px;
    margin-bottom: 10px;
}

#product-quick-view-dialog .product-id-section * {
    font-size: 13px;
}

#product-quick-view-dialog .product-details-section .section-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#product-quick-view-dialog .product-details-section .section-item:first-child {
    margin-bottom: 15px;
}

#product-quick-view-dialog .product-details-section .section-item:last-child {
    margin-bottom: 60px
}

/* Request Product */

#catalogue .products-table .no-products-found {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 40px;
}

#catalogue .products-table .no-products-found > span:nth-of-type(2) {
    font-weight: 600;
    font-size: 14px;
    margin-right: 35px;
}

#catalogue .products-table .no-products-found button {
    position: relative;
    right: 30px;
    font-weight: 600;
    font-size: 14px;
}

#request-new-product .custom-dialog-wrapper {
    width: 450px;
}

#request-new-product .form-view .forgot-password-description {
    margin-top: 30px;
    text-align: center;
}

#request-new-product .form-view .text-field {
    margin-top: 20px;
    margin-bottom: 50px;
}

#request-new-product .form-view .loading-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    min-height: 150px;
}

#request-new-product .submitted-view {
    text-align: center;
}

#request-new-product .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#inputContainerMain{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}
#inputContainerError{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    }
    #inputContainerErrorMsg{
        color: red;
        display: inline;
        margin-bottom: 8px;
}
#request-new-product .description {
    margin-bottom: 30px;
    padding: 0 40px;
    text-align: center;
}

#request-new-product button {
    font-weight: bold;
}
@media screen and (max-width: 767px) { 
    #catalogue .table-title-section span {
        white-space: nowrap;
    }
    #catalogue .table-title-section h3{
       font-size: 18px;
    }
}
@media screen and (max-width: 400px) { 
    #catalogue .products-table .no-products-found button {
        white-space: nowrap;
        font-size: 12px;
    }
}
.titleFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#login-page {
    padding: 0;
}

.login-container {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    align-items: stretch;
}

.login-container .left-side {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/background.94b26254.jpeg);
}

.login-container .right-side {
    background-color: #fff;
    padding: 50px 10% 0 10%;
}

.login-container .right-side .right-side-content .login-form .input-wrapper {
    margin-bottom: 4px;
}

.login-container .right-side .right-side-header {
    text-align: center;
}
.login-container .right-side .right-side-header img {
     width: 350px;
     min-height:80px
}
.login-container .right-side .right-side-header svg {
    height: 17vh;
    min-height: 80px;
}

.login-container .right-side .right-side-header h1 {
    margin-top: 1vh;
}

.login-container .right-side .right-side-content .login-form {
    padding-top: 3vh
}

.login-container .right-side .right-side-content .login-form .text-field:not(:first-child) {
    margin-top: 2vh;
}

.login-container .right-side .right-side-content .content-footer {
    text-align: center;
    margin-top: 10vh;
}

.login-container .right-side .right-side-content .content-footer .submit-button {
    border-radius: 25px;
    margin-bottom: 10px;
}

.login-container .right-side .right-side-content .emails {
    margin-top: 16px;
}

.contact-us-wrapper .text-section {
    justify-content: center;
}

/* FORGOT PASSWORD */
.login-container .right-side .right-side-content .login-form .password-field {
    position: relative;
    margin-top: 10px;
}

.login-container .right-side .right-side-content .login-form .password-field:not(.__has-error) label[for="id_Password"] + .input-wrapper {
    margin-bottom: 25px;
}

.login-container .right-side .right-side-content .login-form .password-field .forgot-password-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
}

.login-container .right-side .right-side-content .login-form .forgot-password-btn {
    font-size: 12px;
}

#forgot-password-dialog .custom-dialog-wrapper {
    width: 450px;
    min-height: 250px;
}

#forgot-password-dialog .form-view .forgot-password-description {
    margin-top: 30px;
    text-align: center;
}

#forgot-password-dialog .form-view .text-field {
    margin-top: 20px;
    margin-bottom: 35px;
}

#forgot-password-dialog .form-view .loading-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

#forgot-password-dialog .submitted-view {
    text-align: center;
}

#forgot-password-dialog .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#forgot-password-dialog .description {
    margin-bottom: 30px;
    padding: 0 40px;
    margin-top: 10px;
}

@media screen and (max-width: 820px) {
    .login-container .left-side {
        display: none;
    }
    .login-container .right-side .right-side-header img {
        width: 300px;
        min-height:80px
   }
}


#bestseller .table-title-section {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}

#bestseller .bestsellers-table {
    width: 100%;
    width: calc(100% + 200px);
}

#bestseller .pantalone-table {
    max-height: 60vh;
}

#bestseller .bestsellers-table .id-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#bestseller .bestsellers-table .id-item svg {
    color: #000;
}

#bestseller .bestsellers-table .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#bestseller .bestsellers-table .last-purchase {
    font-weight: 600;
}

#bestseller .bestsellers-table .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

#bestseller .bestsellers-table td:last-child,
#bestseller .bestsellers-table th:last-child {
    width: 180px;
}

#bestseller .bestsellers-table td:last-child button {
    font-weight: bold;
}


#bestseller .bestsellers-table .reorder-button {
    width: 160px;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
}

@keyframes iconAreaAni {
    0% { opacity: 0;}
    100% { opacity: 1; }
}

#bestseller .bestsellers-table .reorder-button .icon-area {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #16a085;
    color: #fff;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    pointer-events: none;
    animation: iconAreaAni 0.5s alternate ease-in-out;
}

#bestseller .bestsellers-table .reorder-button .icon-area .check-circle-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: iconAreaAni 1s alternate ease-in-out;
}

#bestseller .bestsellers-table + .loading-section {
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
    width: 100%;
}
#bestseller .bestsellers-table .price {
    display: flex;
}

#bestseller .bestsellers-table .price .span {
    margin-right: 5px;
}

@media screen and (max-width: 767px) { 
    #bestseller .table-title-section span {
        white-space: nowrap;
    }
    #bestseller .table-title-section h3{
       font-size: 18px;
    }
}

#landingpage .category-item:not(.__is-chip) {
    flex: 0 0 200px;
    max-width: 200px;
    width: 200px;
    height: 220px;
}

#landingpage .category-item:not(.__is-chip) .__chip-item {
    height: 0;
}

#landingpage .category-item > .MuiPaper-root{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
}

#landingpage .category-item > .MuiPaper-root > button,
#landingpage .category-item > .MuiPaper-root .MuiCardContent-root {
    height: 100%;
}

#landingpage .category-item > .MuiPaper-root .MuiCardContent-root {
    padding: 12px;
}

#landingpage .category-item .MuiCardMedia-root {
    height: 130px;
}

#landingpage .category-item .category-name {
    font-size: 13px;
    font-weight: bold;
}

#landingpage .sub-category-section .divider {
    margin-top: 40px;
    margin-bottom: 50px;
}
#landingpage .sub-category-section-block .divider {
    /* margin-top: 40px; */
    margin-bottom: 50px;
}
#landingpage .__chip-item:not(.__is-selected) {
    background-color: #cbdbea;
    color: #000;
}

#landingpage .__chip-item {
    font-size: 12px;
}

#landingpage .__chip-item.__is-selected {
    font-weight: 600;
}
@media screen and (max-width: 767px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 160px;
        max-width: 160px;
    }
    #landingpage .cattegory-sub-item {
        height: 95% !important;       
    }
    #landingpage .category-item-sub:not(.__is-chip) {
       margin: 12px 0;
    }
}
@media screen and (max-width: 425px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 154px;
        max-width: 154px;
        width: 221px;
        height: 240px;
    }
    #landingpage .category-item .MuiCardMedia-root {
        height: 110px;
    }
    #landingpage .cattegory-sub-item {
        height: 85% !important;       
    }
    #landingpage .category-item-sub:not(.__is-chip) {
        margin: -15px 0;
     }
}
@media screen and (max-width: 375px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 135px;
        max-width: 135px;
        width: 184px;
        height: 240px;
    }
}
@media screen and (max-width: 390px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 135px;
        max-width: 135px;
        width: 184px;
        height: 240px;
    }
    #landingpage .category-item .MuiCardMedia-root {
        height: 100px;
    }
}
@media screen and (max-width: 360px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 128px;
        max-width: 128px;
        width: 184px;
        height: 240px;
    }
}
@media screen and (max-width: 320px) {
    #landingpage .category-item:not(.__is-chip) {
        flex: 0 0 110px;
        max-width: 110px;
        width: 184px;
        height: 215px;
    }
    #landingpage .category-item .category-name {
        font-size: 10px;
    }
}
#quote-management {
    position: relative;
}

#quote-management .active-order-section .top-section .order-details p {
    position: absolute;
    right: 29px;
    top: 42px;
}

#quote-management .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 60px;
    margin-bottom: 25px;
}

#quote-management .active-order-section .top-section .order-section-card {
    border: 1px solid #afafaf;
    padding: 10px 16px;
    margin-top: 24px;
    border-radius: 0;
    margin-bottom: 80px;
}

#quote-management .active-order-section .top-section .order-section-card .order-detail-item,
#quote-management .active-order-section .top-section .order-section-card .section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
}

#quote-management .active-order-section .top-section .order-section-card .order-detail-item:not(:first-child),
#quote-management .active-order-section .top-section .order-section-card .section-item:not(:first-child) {
    margin-top: 18px;
}

#quote-management .active-order-section .top-section .order-section-card .order-detail-item strong {
    color: #000;
    font-size: 15px;
}

#quote-management .active-order-section .table-title {
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#quote-management .page-title {
    margin-bottom: 25px;
}

#quote-management .title-section .page-title {
    margin-bottom: 0;
}

#quote-management .title-section .filter-button {
    font-weight: 500;
}

#quote-management .title-section .filter-button span {
    margin-right: 8px;
    font-size: 15px;
    font-weight: normal;
}

#quote-management .title-section .filter-button svg {
    font-size: 22px;
}

#quote-management .saved-quotes-table td:last-child button:last-child {
    color: #f41216;
}

#quote-management .saved-quotes-table .shipping-address-item b {
    display: block;
}

#quote-management .saved-quotes-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}

#quote-management .confirmed-quotes-table .shipping-address-item b {
    display: block;
}

#quote-management .confirmed-quotes-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}

#quote-management .saved-quotes-table thead tr th:nth-child(2) {
    width: 23%;
}

#quote-management .confirmed-quotes-table thead tr th:nth-child(3) {
    width: 16%;
}

#quote-management .confirmed-quotes-table,
#quote-management .saved-quotes-table {
    max-height: 50vh;
}

#quote-management .confirmed-quotes-table {
    width: 166%;
}

#quote-management .saved-quotes-table th:last-child,
#quote-management .confirmed-quotes-table th:last-child {
    width: 145px;
}

#quote-management .confirmed-quotes-table .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#quote-management .cart-table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d0d0d0;
    padding: 16px 45px 16px 30px;
}

#quote-management .cart-table-footer .total-cart-amount {
    font-weight: 700;
    font-size: 19px;
    /* white-space: nowrap; */
    margin-left: -23px;
    margin-right: 35px;
}

#quote-management .cart-table-footer .price {
    display: flex;
    white-space: nowrap;
}

#quote-management .saved-quotes-table .price .span {
    margin-right: 5px;
}

#quote-management .cart-table-footer .price .span {
    margin-right: 5px;
}

#quote-management .confirmed-quotes-table .price .span {
    margin-right: 5px;
}

#quote-management .active-order-section .price {
    display: flex;
}

#quote-management .active-order-section .price .span {
    margin-right: 5px;
}

#quote-management .button-group {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#quote-management .button-group>button {
    width: 300px;
}

#quote-management .button-group a {
    width: 300px;
    margin-right: 20px;
}

#quote-management .button-group>button:not(:last-child) {
    margin-right: 20px;
}

#quote-management .cart-table.__is-checkout {
    width: 120vw;
    width: calc(100vw + 20%);
}

#quote-management .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#quote-management .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

.Btn-Flex {
    display: flex;
    flex-direction: row;
    grid-column-gap: 10px;
    -moz-column-gap: 10px;
         column-gap: 10px;
}

@supports (position:sticky) {
    #quote-management .confirmed-quotes-table thead th:last-child {
        position: sticky;
        right: 0;
    }

    #quote-management .confirmQuoteTableBody td:last-child {
        position: sticky;
        right: 0;
        background-color: #cbdce9;
    }
    #quote-management .savedQuoteTableBody td:last-child {
        position: sticky;
        right: 0;
        background-color: #cbdce9;
    }
}

@media screen and (max-width: 767px) {
    #quote-management .active-order-section .top-section .order-section-card {
        width: 240px;
    }

    #quote-management .button-group>button:not(:last-child) {
        margin-bottom: 15px;
    }

    #quote-management .button-group {
        display: block;
    }

    #quote-management .button-group>button {
        max-width: 100% !important;
        font-size: 13px;
    }

    #quote-management .button-group a>button {
        max-width: 100% !important;
        margin-bottom: 16px;
        font-size: 13px;
    }

    #quote-management .cart-table-footer .total-cart-amount {
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {
    #quote-management .active-order-section .top-section .order-section-card {
        width: 215px;
    }

    #quote-management .active-order-section .top-section .order-details p {
        position: absolute;
        right: 12px;
        font-size: 14px;
    }
}
#reset-password-page {
  padding: 0;
}

.reset-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  align-items: stretch;
}

.reset-container .left-side {
  background-repeat: no-repeat;
  background-size: cover;
}

.reset-container .right-side {
  background-color: #fff;
  max-width: 500px;
  margin: 5% auto;
  padding: 10px;
}

.reset-container .right-side .right-side-content .reset-form .input-wrapper {
  margin-bottom: 4px;
}

.reset-container .right-side .right-side-header {
  text-align: center;
}

.reset-container .right-side .right-side-header svg {
  height: 17vh;
  min-height: 80px;
}

.reset-container .right-side .right-side-header h1 {
  margin-top: 4vh;
}

.reset-container .right-side .right-side-content .reset-form {
  padding-top: 3vh;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .text-field:not(:first-child) {
  margin-top: 2vh;
}

.reset-container .right-side .right-side-content .content-footer {
  text-align: center;
  margin-top: 6vh;
}

.reset-container
  .right-side
  .right-side-content
  .content-footer
  .submit-button {
  border-radius: 25px;
  margin-bottom: 10px;
}

.reset-container .right-side .right-side-content .emails {
  margin-top: 16px;
}

.contact-us-wrapper .text-section {
  justify-content: center;
}

/* FORGOT PASSWORD */
.reset-container .right-side .right-side-content .reset-form .password-field {
  position: relative;
  margin-top: 10px;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .password-field:not(.__has-error)
  label[for="id_Password"]
  + .input-wrapper {
  margin-bottom: 25px;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .password-field
  .forgot-password-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .forgot-password-btn {
  font-size: 12px;
}

#forgot-password-dialog .custom-dialog-wrapper {
  width: 450px;
  min-height: 250px;
}

#forgot-password-dialog .form-view .forgot-password-description {
  margin-top: 30px;
  text-align: center;
}

#forgot-password-dialog .form-view .text-field {
  margin-top: 20px;
  margin-bottom: 35px;
}

#forgot-password-dialog .form-view .loading-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

#forgot-password-dialog .submitted-view {
  text-align: center;
}

#forgot-password-dialog .check-icon {
  color: #41c702;
  width: 40px;
  height: 40px;
}

#forgot-password-dialog .description {
  margin-bottom: 30px;
  padding: 0 40px;
  margin-top: 10px;
}

#shipment-document .shipment-document-table .shipping-address-item b {
    display: block;
}

#shipment-document .shipment-document-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    max-width: 250px;
}
#shipment-document .shipment-document-table  thead tr th:nth-child(4) {
    width: 26%;
 }

#shipment-document .shipment-document-second-table .shipping-address-item b {
    display: block;
}

#shipment-document .shipment-document-second-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    max-width: 250px;
}
#shipment-document .shipment-document-second-table  thead tr th:nth-child(2) {
    width: 28%;
 }
 #shipment-document .shipment-document-second-table  thead tr th:nth-child(1) {
    width: 12%;
 }
 #shipment-document .shipment-document-second-table  thead tr th:nth-child(3),
 #shipment-document .shipment-document-second-table  thead tr th:nth-child(4)  {
    width: 15%;
 }

 #shipment-document .shipment-document-second-table  thead tr th:nth-child(5) {
    width: 12%;
 }

