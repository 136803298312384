@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

html, body, #root {
    height: 100%;
}

body {
    overflow: hidden;
}

#root > div {
    width: 100%!important;
    height: auto!important;
}

#root > div > div:nth-of-type(1) {
    display: none!important;
}

/* HEADER */
header {
    height: 120px;
    background-color: #e6e6e6;
    width: 100%;
}

header .header-wrapper {
    padding: 10px 50px 0 15px;
    height: 100%;
}

header .header-wrapper-left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

header .header-wrapper .header-wrapper-left-side .logo-button {
    background-color: unset!important;
    padding: 0;
    flex: 0 0 225px;
    max-width: 225px;
}

header .header-wrapper .header-wrapper-left-side .logo-button img{
    width: 235px;
}

header .header-wrapper .header-wrapper-left-side form {
    width: 100%;
}

header .header-wrapper .header-wrapper-left-side .text-field {
    margin-left: 46px;
}

header .header-wrapper .header-wrapper-left-side .text-field input {
    border-radius: 25px;
    border: 0;
    max-width: 100%;
    font-weight: 500;
    padding-left: 25px;
    padding-right: 25px;
    height: 45px;
    font-size: 16px;
    font-weight: normal;
}

header .header-wrapper .header-wrapper-right-side {
    text-align: right;
    margin-top: -20px;
}

header .header-wrapper .header-wrapper-right-side .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header .header-wrapper .header-wrapper-right-side .top .language-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header .header-wrapper .header-wrapper-right-side .top .language-selector .divider {
    height: 15px;
    width: 2px;
    background-color: #000;
    min-height: 0;
}

header .header-wrapper .header-wrapper-right-side .top .language-selector button {
    min-width: 0;
    font-size: 12px;
}

header .header-wrapper .header-wrapper-right-side .top .logout-button {
    margin-left: 25px;
    font-size: 12px;
}
header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
    margin-left: 24px;
    margin-right: 14px;
    font-size: 12px;
}

header .header-wrapper .header-wrapper-right-side .bottom button small {
    font-size: 13px;
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
}
header .header-wrapper .header-wrapper-right-side .bottom .badge-none .MuiBadge-badge{
   display: none;
}
header .header-wrapper .header-wrapper-right-side .bottom .badge-block .MuiBadge-badge {
    display: block;
    padding: 4px 6px;
 }
header .header-wrapper .header-wrapper-right-side .bottom button > span {
    flex-wrap: wrap;
}

header .header-wrapper .header-wrapper-right-side .bottom button svg {
    flex: 1 1 100%;
    max-width: 100%;
    font-size: 30px;
}

/* CONTENT */
.main-layout-wrapper {
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    height: calc(100% - 120px);
    flex-wrap: nowrap!important;
}

.main-layout-without-sidebar {
    padding: 15px 30px;
    height: 100%;
    height: calc(100vh - 120px);
    overflow: auto;
}

.main-layout-wrapper.__use-header,
.main-layout-without-sidebar.__use-header {
    height: calc(100vh - 120px);
}

.main-layout-wrapper:not(.__use-header),
.main-layout-without-sidebar:not(.__use-header) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.main-layout-wrapper aside {
    border: 1px solid #afafaf;
    border-left:0;
    border-bottom: 0;
    height: auto;
    flex: 0 0 260px;
    max-width: 260px;
    width: 260px;
    padding-top: 30px;
    position: relative;
}

.main-layout-wrapper .open{
    border: 1px solid #afafaf;
    border-left:0;
    border-bottom: 0;
    height: auto;
    flex: 0 0 260px;
    max-width: 260px;
    width: 260px;
    padding-top: 30px;
    position: relative;
}

.main-layout-wrapper aside .menu-items button {
    font-size: 18px;
    border-radius: 0;
    font-weight: 600;
}
.main-layout-wrapper aside .menu-items button span {
    padding-right: 13.5px;
}
.main-layout-wrapper aside .menu-items button:not(.__is-active) {
    color: #969696;
}

.main-layout-wrapper aside .menu-items button:not(:last-child) {
    margin-bottom: 20px;
}

.main-layout-wrapper aside .menu-items button > span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
}

.main-layout-wrapper aside .menu-items button .shipments-item {
    text-align: left;
}

.main-layout-wrapper aside .menu-items button .shipments-item > span {
    display: inline-block;
    text-align: left;
}

.main-layout-wrapper aside .menu-items button svg {
    font-size: 26px;
    margin-right: 20px;
}

.main-layout-wrapper .content {
    flex: 1 1 auto;
    max-width: 100%;
    padding: 45px 30px 45px 30px;
    height: 100%;
    height: calc(100vh - 120px);
    overflow: auto;
}

.main-layout-wrapper .content .page-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 700;
}

.main-layout-wrapper .content .padding-box {
    padding-bottom: 45px;
}
.main-layout-wrapper .menu-items .toggle-button {       
   display: none;
}

@media screen and (max-width: 767px) {
    .main-layout-wrapper aside .menu-items {
        margin-top: 25px;
    } 
    header .header-wrapper {
        padding: 0px 25px 0 15px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button {
        background-color: unset!important;
        padding: 0;
        flex: 0 0 180px;
        max-width: 180px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button img{
        width: 200px;
        margin-bottom: 40px;
    }
    .main-layout-wrapper aside {
       overflow: auto;
       overflow-x: hidden;
    }
    header {
        height: 150px;
    }
    header .header-wrapper {
        position: relative;
    }
    header .header-wrapper .header-wrapper-left-side form {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 15px;
        margin-bottom: 14px;
        margin-left: -19px;
    }
    header .header-wrapper .header-wrapper-left-side .text-field {
        margin-left: 20px;
    }
    header .header-wrapper .header-wrapper-right-side .top {
        margin-top: -45px;
    }
    .main-layout-wrapper .closed {       
        flex: 0 0 60px;
        max-width: 260px;
    }
    .main-layout-wrapper .menu-items .toggle-button {  
        display: block;     
        position: absolute;
        top: 0;
        margin-left: -4px;
        margin-top: 9px;
    }
    .main-layout-wrapper .menu-items .toggle-button-cross-icon {  
        position: absolute;
        top: 1px;
        right: -21px;
    }
    .main-layout-wrapper aside .menu-items button > span {
        padding-left: 12px;
    }
    .main-layout-wrapper aside .menu-items button svg {
        margin-right: -8px;
    }
    .main-layout-wrapper aside .menu-items button span {
        padding-right: 32.5px;
    }
     header .header-wrapper .header-wrapper-right-side .bottom button small {
        display: none;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        margin-left: -3px;
        margin-right: 0;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-left: -2px;
    }
    .main-layout-wrapper.__use-header,
    .main-layout-without-sidebar.__use-header {
        height: calc(100vh - 170px);
    }
    #landingpage .category-item .category-name {
        font-size: 10px;
    }
    .main-layout-wrapper .closed .menu-items button .MuiButton-label span:last-child{
        display: none;
     } 
     .text-field.__full-width {
        max-width: 100% !important;
    }
    .main-layout-wrapper .content .page-title {
        font-size: 16px;
    }
}

@media screen and (max-width: 540px) {   
    header .header-wrapper .header-wrapper-right-side .bottom {
        display: flex;
     }
}
@media screen and (max-width: 412px) {
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-right: 0px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        margin-right: -10px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-right: -10px;
    }
}

@media screen and (max-width: 375px) {
    header .header-wrapper .header-wrapper-right-side .bottom button small {
        display: none;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        margin-right: -15px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        margin-right: -15px;
    }
}

@media screen and (max-width: 320px) {
    header .header-wrapper {
        padding: 0px 40px 0 15px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button {
        background-color: unset!important;
        padding: 0;
        flex: 0 0 140px;
        max-width: 140px;
    }
    header .header-wrapper .header-wrapper-left-side .logo-button img{
        width: 150px;
        margin-bottom: 40px;
    }
    header .header-wrapper .header-wrapper-right-side .top .language-selector button {
        font-size: 10px;
    }
    
    header .header-wrapper .header-wrapper-right-side .top .logout-button {
        font-size: 10px;
        margin-right: -40px;
    }
    header .header-wrapper .header-wrapper-right-side .top .logout-button-it {
        font-size: 10px;
        margin-right: -40px;
    }
    header .header-wrapper .header-wrapper-right-side .bottom button svg {
        font-size: 25px;
    }
    .main-layout-wrapper .closed {
        flex: 0 0 50px;
        max-width: 260px;
    }
    .main-layout-wrapper aside .menu-items button > span{
        padding-left: 7px;
    } 
    .main-layout-wrapper .closed .menu-items button .MuiButton-label span:last-child{
       display: none;
    }       
    .main-layout-wrapper aside .menu-items button svg {
        font-size: 22px;
    }
    header .header-wrapper .header-wrapper-left-side .text-field input {
        height: 40px;
    }
    header .header-wrapper .header-wrapper-right-side .top {
        margin-top: -28px;
    }
    .main-layout-wrapper aside .menu-items button span {
        padding-right: 35.5px;
    }
    #landingpage .category-item .category-name {
        font-size: 8px;
    }
}