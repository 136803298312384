#quote-management {
    position: relative;
}

#quote-management .active-order-section .top-section .order-details p {
    position: absolute;
    right: 29px;
    top: 42px;
}

#quote-management .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 60px;
    margin-bottom: 25px;
}

#quote-management .active-order-section .top-section .order-section-card {
    border: 1px solid #afafaf;
    padding: 10px 16px;
    margin-top: 24px;
    border-radius: 0;
    margin-bottom: 80px;
}

#quote-management .active-order-section .top-section .order-section-card .order-detail-item,
#quote-management .active-order-section .top-section .order-section-card .section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
}

#quote-management .active-order-section .top-section .order-section-card .order-detail-item:not(:first-child),
#quote-management .active-order-section .top-section .order-section-card .section-item:not(:first-child) {
    margin-top: 18px;
}

#quote-management .active-order-section .top-section .order-section-card .order-detail-item strong {
    color: #000;
    font-size: 15px;
}

#quote-management .active-order-section .table-title {
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#quote-management .page-title {
    margin-bottom: 25px;
}

#quote-management .title-section .page-title {
    margin-bottom: 0;
}

#quote-management .title-section .filter-button {
    font-weight: 500;
}

#quote-management .title-section .filter-button span {
    margin-right: 8px;
    font-size: 15px;
    font-weight: normal;
}

#quote-management .title-section .filter-button svg {
    font-size: 22px;
}

#quote-management .saved-quotes-table td:last-child button:last-child {
    color: #f41216;
}

#quote-management .saved-quotes-table .shipping-address-item b {
    display: block;
}

#quote-management .saved-quotes-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}

#quote-management .confirmed-quotes-table .shipping-address-item b {
    display: block;
}

#quote-management .confirmed-quotes-table .shipping-address-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}

#quote-management .saved-quotes-table thead tr th:nth-child(2) {
    width: 23%;
}

#quote-management .confirmed-quotes-table thead tr th:nth-child(3) {
    width: 16%;
}

#quote-management .confirmed-quotes-table,
#quote-management .saved-quotes-table {
    max-height: 50vh;
}

#quote-management .confirmed-quotes-table {
    width: 166%;
}

#quote-management .saved-quotes-table th:last-child,
#quote-management .confirmed-quotes-table th:last-child {
    width: 145px;
}

#quote-management .confirmed-quotes-table .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#quote-management .cart-table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d0d0d0;
    padding: 16px 45px 16px 30px;
}

#quote-management .cart-table-footer .total-cart-amount {
    font-weight: 700;
    font-size: 19px;
    /* white-space: nowrap; */
    margin-left: -23px;
    margin-right: 35px;
}

#quote-management .cart-table-footer .price {
    display: flex;
    white-space: nowrap;
}

#quote-management .saved-quotes-table .price .span {
    margin-right: 5px;
}

#quote-management .cart-table-footer .price .span {
    margin-right: 5px;
}

#quote-management .confirmed-quotes-table .price .span {
    margin-right: 5px;
}

#quote-management .active-order-section .price {
    display: flex;
}

#quote-management .active-order-section .price .span {
    margin-right: 5px;
}

#quote-management .button-group {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#quote-management .button-group>button {
    width: 300px;
}

#quote-management .button-group a {
    width: 300px;
    margin-right: 20px;
}

#quote-management .button-group>button:not(:last-child) {
    margin-right: 20px;
}

#quote-management .cart-table.__is-checkout {
    width: 120vw;
    width: calc(100vw + 20%);
}

#quote-management .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#quote-management .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

.Btn-Flex {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

@supports (position:sticky) {
    #quote-management .confirmed-quotes-table thead th:last-child {
        position: sticky;
        right: 0;
    }

    #quote-management .confirmQuoteTableBody td:last-child {
        position: sticky;
        right: 0;
        background-color: #cbdce9;
    }
    #quote-management .savedQuoteTableBody td:last-child {
        position: sticky;
        right: 0;
        background-color: #cbdce9;
    }
}

@media screen and (max-width: 767px) {
    #quote-management .active-order-section .top-section .order-section-card {
        width: 240px;
    }

    #quote-management .button-group>button:not(:last-child) {
        margin-bottom: 15px;
    }

    #quote-management .button-group {
        display: block;
    }

    #quote-management .button-group>button {
        max-width: 100% !important;
        font-size: 13px;
    }

    #quote-management .button-group a>button {
        max-width: 100% !important;
        margin-bottom: 16px;
        font-size: 13px;
    }

    #quote-management .cart-table-footer .total-cart-amount {
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {
    #quote-management .active-order-section .top-section .order-section-card {
        width: 215px;
    }

    #quote-management .active-order-section .top-section .order-details p {
        position: absolute;
        right: 12px;
        font-size: 14px;
    }
}