
/* DIALOG */
.custom-dialog {
    border-radius: 8px;
    position: relative;
}

.custom-dialog .custom-dialog-wrapper {
    padding: 20px;
    min-width: 300px;
    max-width: 500px;
    /* max-width: 100%; */
}

.custom-dialog .custom-dialog-wrapper .dialog-title {
    text-align: center;
    margin-bottom: 16px;
}

.custom-dialog .custom-dialog-wrapper .close-button {
    position: absolute;
    right: 0;
    top:0;
    color: #000;
}

.custom-dialog .custom-dialog-wrapper .close-button svg {
    font-size: 15px;
}

.react-router-modal__container {
    z-index: 1500;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .custom-dialog .MuiBackdrop-root {
        backdrop-filter: -webkit-blur(2px);
        backdrop-filter: blur(2px);
    }
}


/* INPUT */
.text-field {}

.text-field.__full-width {
    width: 100%;
}

.text-field.__full-width input,
.text-field.__full-width textarea {
    width: 100%;
}

.text-field.__start-adornment .input-wrapper input {
    padding-left: 50px;
}

.text-field.__end-adornment .input-wrapper input {
    padding-right: 50px;
}

.text-field label {
    display: inline-block;
    margin-bottom: 4px;
}

.text-field .input-wrapper {
    position: relative;
}

.text-field .input-wrapper input {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    height: 40px;
    min-width: 220px;
    padding: 0 8px;
    outline: none;
    background-color: #fff;
    font-weight: 500;
    outline: unset;
}

.text-field .input-wrapper textarea {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    min-width: 220px;
    min-height: 60px;
    padding: 10px;
    outline: none;
    background-color: #fff;
    font-weight: 500;
    outline: unset;
}

.text-field .input-wrapper input:not(:focus):not(:hover),
.text-field .input-wrapper textarea:not(:focus):not(:hover) {
    border-color: #cac9c9!important;
}

.text-field .input-wrapper .start-adornment {
    position: absolute;
    left: 0;
    top:0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 4px;
    padding-right: 4px;
}

.text-field .input-wrapper .end-adornment {
    position: absolute;
    right: 0;
    top:0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 4px;
    padding-right: 4px;
}

.text-field .input-wrapper .start-adornment button,
.text-field .input-wrapper .end-adornment button {
    height: 100%;
    width: 40px;
}

.text-field .error-message {
    font-size: 12px;
    font-weight: 600;
    color: #f41216;
}

.text-field .extra-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

/* BUTTONS */
.rounded-button {
    border-radius: 25px;
}

/* Contact Us Dialog */
#contact-us-dialog .custom-dialog-wrapper  {
    width: 400px;
    padding-bottom: 50px;
}

#contact-us-dialog .emails {
    margin-top: 10px;
}

#contact-us-dialog .emails a {
    display: block;
    text-align: center;
    font-size: 17px;
}

#contact-us-dialog .emails a:not(:last-child) {
    margin-bottom: 15px;
}

.contact-us-wrapper .text-section {
    display: flex;
    align-items: center;
}

.contact-us-wrapper .text-section .contact-us-text {
    font-weight: bold;
    margin-right: 4px;
    user-select: none;
    cursor: pointer;
}
.react-router-modal__modal {
    padding: 20px;
    border-radius: 8px;
    min-width: 300px;
    text-align: center;
}

.react-router-modal__modal * {
    justify-content: center;
    margin-left: 0;
}

.react-router-modal__modal div[role="button"] {
    width: 150px;
}

.react-router-modal__modal > div > div:nth-of-type(1) {
    font-size: 24px;
}

.react-router-modal__modal > div > div:nth-of-type(2) {
    font-size: 16px;
}

/* Pantalone Table */
.pantalone-table {
    box-shadow: none!important;
}

.pantalone-table table {
    border-collapse: unset;
}

.pantalone-table thead th {
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #d0d0d0;
    border-right-width: 0;
    border-left-width: 0;
}

.pantalone-table thead th:first-child {
    border-left-width: 1px;
}

.pantalone-table thead th:last-child {
    border-right-width: 1px;
}

.pantalone-table tbody {
    background-color: #cbdce9;
}

.pantalone-table tbody td {
    font-size: 14px;
    border-bottom: 1px solid #d0d0d0;
    max-width: 300px;
}