#reset-password-page {
  padding: 0;
}

.reset-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  align-items: stretch;
}

.reset-container .left-side {
  background-repeat: no-repeat;
  background-size: cover;
}

.reset-container .right-side {
  background-color: #fff;
  max-width: 500px;
  margin: 5% auto;
  padding: 10px;
}

.reset-container .right-side .right-side-content .reset-form .input-wrapper {
  margin-bottom: 4px;
}

.reset-container .right-side .right-side-header {
  text-align: center;
}

.reset-container .right-side .right-side-header svg {
  height: 17vh;
  min-height: 80px;
}

.reset-container .right-side .right-side-header h1 {
  margin-top: 4vh;
}

.reset-container .right-side .right-side-content .reset-form {
  padding-top: 3vh;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .text-field:not(:first-child) {
  margin-top: 2vh;
}

.reset-container .right-side .right-side-content .content-footer {
  text-align: center;
  margin-top: 6vh;
}

.reset-container
  .right-side
  .right-side-content
  .content-footer
  .submit-button {
  border-radius: 25px;
  margin-bottom: 10px;
}

.reset-container .right-side .right-side-content .emails {
  margin-top: 16px;
}

.contact-us-wrapper .text-section {
  justify-content: center;
}

/* FORGOT PASSWORD */
.reset-container .right-side .right-side-content .reset-form .password-field {
  position: relative;
  margin-top: 10px;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .password-field:not(.__has-error)
  label[for="id_Password"]
  + .input-wrapper {
  margin-bottom: 25px;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .password-field
  .forgot-password-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
}

.reset-container
  .right-side
  .right-side-content
  .reset-form
  .forgot-password-btn {
  font-size: 12px;
}

#forgot-password-dialog .custom-dialog-wrapper {
  width: 450px;
  min-height: 250px;
}

#forgot-password-dialog .form-view .forgot-password-description {
  margin-top: 30px;
  text-align: center;
}

#forgot-password-dialog .form-view .text-field {
  margin-top: 20px;
  margin-bottom: 35px;
}

#forgot-password-dialog .form-view .loading-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

#forgot-password-dialog .submitted-view {
  text-align: center;
}

#forgot-password-dialog .check-icon {
  color: #41c702;
  width: 40px;
  height: 40px;
}

#forgot-password-dialog .description {
  margin-bottom: 30px;
  padding: 0 40px;
  margin-top: 10px;
}
