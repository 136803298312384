
#login-page {
    padding: 0;
}

.login-container {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    align-items: stretch;
}

.login-container .left-side {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../blocks/email-account-login/assets/background.jpeg');
}

.login-container .right-side {
    background-color: #fff;
    padding: 50px 10% 0 10%;
}

.login-container .right-side .right-side-content .login-form .input-wrapper {
    margin-bottom: 4px;
}

.login-container .right-side .right-side-header {
    text-align: center;
}
.login-container .right-side .right-side-header img {
     width: 350px;
     min-height:80px
}
.login-container .right-side .right-side-header svg {
    height: 17vh;
    min-height: 80px;
}

.login-container .right-side .right-side-header h1 {
    margin-top: 1vh;
}

.login-container .right-side .right-side-content .login-form {
    padding-top: 3vh
}

.login-container .right-side .right-side-content .login-form .text-field:not(:first-child) {
    margin-top: 2vh;
}

.login-container .right-side .right-side-content .content-footer {
    text-align: center;
    margin-top: 10vh;
}

.login-container .right-side .right-side-content .content-footer .submit-button {
    border-radius: 25px;
    margin-bottom: 10px;
}

.login-container .right-side .right-side-content .emails {
    margin-top: 16px;
}

.contact-us-wrapper .text-section {
    justify-content: center;
}

/* FORGOT PASSWORD */
.login-container .right-side .right-side-content .login-form .password-field {
    position: relative;
    margin-top: 10px;
}

.login-container .right-side .right-side-content .login-form .password-field:not(.__has-error) label[for="id_Password"] + .input-wrapper {
    margin-bottom: 25px;
}

.login-container .right-side .right-side-content .login-form .password-field .forgot-password-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
}

.login-container .right-side .right-side-content .login-form .forgot-password-btn {
    font-size: 12px;
}

#forgot-password-dialog .custom-dialog-wrapper {
    width: 450px;
    min-height: 250px;
}

#forgot-password-dialog .form-view .forgot-password-description {
    margin-top: 30px;
    text-align: center;
}

#forgot-password-dialog .form-view .text-field {
    margin-top: 20px;
    margin-bottom: 35px;
}

#forgot-password-dialog .form-view .loading-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

#forgot-password-dialog .submitted-view {
    text-align: center;
}

#forgot-password-dialog .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#forgot-password-dialog .description {
    margin-bottom: 30px;
    padding: 0 40px;
    margin-top: 10px;
}

@media screen and (max-width: 820px) {
    .login-container .left-side {
        display: none;
    }
    .login-container .right-side .right-side-header img {
        width: 300px;
        min-height:80px
   }
}
