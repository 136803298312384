
#profile-page .center-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#profile-page .grid-container .item:not(:last-child) {
    margin-bottom: 25px;
}

#profile-page .grid-container .item > label {
    font-weight: 600;
    display: block;
    margin-bottom: 18px;
    font-size: 15px;
}

#profile-page .grid-container .item > .value {
    margin-left: 15px;
    font-weight: 600;
    font-size: 15px;
}

#mobile-phone-info .MuiPaper-root {
    padding: 15px 25px;
    max-width: 100%;
    width: 280px;
    position: relative;
    left: 50px;
    top: 50px;
    font-size: 14px;
}

#mobile-phone-info .MuiPaper-root b {
    font-weight: 600;
}

.mobile-phone-info-contact-us .text-section span {
    font-size: 14px;
}


#profile-page .grid-container .left-side .phone-number-item .value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#profile-page .grid-container .left-side .phone-number-item .value > span {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}


#profile-page .grid-container .left-side .phone-number-item .value svg {
    position: relative;
    top: 2px;
    font-size: 28px;
    left: -3px;
}

#profile-page .grid-container .right-side .password-item > button,
#profile-page .grid-container .right-side .shipping-address-item > button {
    margin-top: 20px;
    margin-left: auto;
    display: block;
}

#profile-page .grid-container .right-side .shipping-address-item textarea {
    background-color: #cbdbea;
    resize: none;
    height: 150px;
    padding-top: 0;
    line-height: 250%;
    font-size: 16px;
    font-weight: 700;
}

#profile-page .grid-container .right-side .shipping-address-item strong {
    font-weight: 600;
}

#profile-page .grid-container .right-side .shipping-address-item .text-section {
    justify-content: flex-start;
}

/* Request New Address */

#request-new-address-dialog .new-address-section .description {
    mask-type: 40px;
    max-width: 70%;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}

#request-new-address-dialog .new-address-section .text-field textarea {
    height: 200px;
}

#request-new-address-dialog .new-address-section > button {
    margin-top: 20px;
}

#request-new-address-dialog .new-address-section .loading-section {
    text-align: center;
    width: 100%;
    height: 200px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

#request-new-address-dialog .new-address-success-section {
    text-align: center;
    width: 400px;
    max-width: 100%;
    max-height: 100%;
}

#request-new-address-dialog .new-address-success-section .check-icon {
    color: #41c702;
    width: 40px;
    height: 40px;
}

#request-new-address-dialog .new-address-success-section .description {
    margin-bottom: 30px;
    padding: 0 40px;
}

#request-new-address-dialog .error-message {
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
}

@media screen and (max-width: 767px) { 
    #profile-page .grid-container .right-side .password-item > button,
    #profile-page .grid-container .right-side .shipping-address-item > button {
        font-size: 13px;
    }
    #profile-page .grid-container .right-side .shipping-address-item .text-section span{
        font-size: 14px;
    }
    #profile-page .grid-container .right-side .shipping-address-item .text-section > button span{
        font-size: 14px;
        white-space: nowrap;
    }
}