
#bestseller .table-title-section {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}

#bestseller .bestsellers-table {
    width: 100%;
    width: calc(100% + 200px);
}

#bestseller .pantalone-table {
    max-height: 60vh;
}

#bestseller .bestsellers-table .id-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#bestseller .bestsellers-table .id-item svg {
    color: #000;
}

#bestseller .bestsellers-table .quantity-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#bestseller .bestsellers-table .last-purchase {
    font-weight: 600;
}

#bestseller .bestsellers-table .quantity-input input {
    width: 100px;
    min-width: unset;
    margin-right: 4px;
}

#bestseller .bestsellers-table td:last-child,
#bestseller .bestsellers-table th:last-child {
    width: 180px;
}

#bestseller .bestsellers-table td:last-child button {
    font-weight: bold;
}


#bestseller .bestsellers-table .reorder-button {
    width: 160px;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
}

@keyframes iconAreaAni {
    0% { opacity: 0;}
    100% { opacity: 1; }
}

#bestseller .bestsellers-table .reorder-button .icon-area {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #16a085;
    color: #fff;
    user-select: none;
    pointer-events: none;
    animation: iconAreaAni 0.5s alternate ease-in-out;
}

#bestseller .bestsellers-table .reorder-button .icon-area .check-circle-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: iconAreaAni 1s alternate ease-in-out;
}

#bestseller .bestsellers-table + .loading-section {
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
    width: 100%;
}
#bestseller .bestsellers-table .price {
    display: flex;
}

#bestseller .bestsellers-table .price .span {
    margin-right: 5px;
}

@media screen and (max-width: 767px) { 
    #bestseller .table-title-section span {
        white-space: nowrap;
    }
    #bestseller .table-title-section h3{
       font-size: 18px;
    }
}